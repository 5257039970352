import { BLANK_PDF, Template } from "@pdfme/common";
import { ITemplate } from "../../entities/organization";
import { ITemplateData } from "../../entities/table";
import { formatDate } from "../helper";

export const defaultTemplate: Template = {
    schemas: [],
    basePdf: BLANK_PDF
}

export const getDoctoratTemplateLabels = () => ({
  label_name: 'Mme/M.',
  underline_name: getUnderLine(70),
  label_ine: 'INE',
  underline_ine: getUnderLine(72),
  label_birth_date: 'Né(e) le',
  underline_birth_date: getUnderLine(34),
  label_birth_at: 'À',
  underline_birth_at: getUnderLine(33),
  label_mention: 'Mention',
  underline_mention: getUnderLine(15),
  label_session: 'Au titre de l\'année universitaire',
  underline_session: getUnderLine(20),
  label_grade: 'Lui conférant le grade de',
  underline_grade: getUnderLine(21),
  label_end_text: 'En foi de quoi le présent diplôme lui est délivré pour servir et valoir ce que de droit.',
  label_edited_at: 'Fait à',
  underline_edited_at: getUnderLine(13),
  label_edited_on: 'Le',
  underline_edited_on: getUnderLine(15),
  label_doctorat_program: 'A obtenu le doctorat en',
  underline_program: getUnderLine(62),
  label_intitule_diplome: 'Intitulé de la thèse',
  underline_intitule_diplome_1: getUnderLine(65),
  underline_intitule_diplome_2: getUnderLine(39),
  underline_doctorat_session: getUnderLine(21),
  underline_doctorat_grade: getUnderLine(21),
  underline_doctorat_mention: getUnderLine(29)
});

const getDefaultTemplateLabels = () => ({
  label_name: 'Mme/M.',
  underline_name: getUnderLine(68),
  label_ine: 'INE',
  underline_ine: getUnderLine(71),
  label_birth_date: 'Né(e) le',
  underline_birth_date: getUnderLine(33),
  label_birth_at: 'À',
  underline_birth_at: getUnderLine(31),
  label_program: 'A satisfait aux exigences du programme ',
  underline_program: getUnderLine(50),
  label_speciality: 'Spécialité',
  underline_speciality: getUnderLine(67),
  label_finalite: 'Finalité',
  underline_finalite: getUnderLine(35),
  label_mention: 'Mention',
  underline_mention: getUnderLine(28),
  label_session: 'Au titre de l\'année universitaire',
  underline_session: getUnderLine(23),
  label_grade: 'Lui conférant le grade de',
  underline_grade: getUnderLine(19),
  label_end_text: 'En foi de quoi le présent diplôme lui est délivré pour servir et valoir ce que de droit.',
  label_edited_at: 'Fait à',
  underline_edited_at: getUnderLine(13),
  label_edited_on: 'Le',
  underline_edited_on: getUnderLine(13),
  label_doctorat_program: 'A obtenu le doctorat en',
  label_intitule_diplome: 'Intitulé de la thèse',
  underline_intitule_diplome_1: getUnderLine(65),
  underline_intitule_diplome_2: getUnderLine(39),
  underline_doctorat_session: getUnderLine(21),
  underline_doctorat_grade: getUnderLine(21),
  underline_doctorat_mention: getUnderLine(29)
});

function isVowel(letter: string) {
  return ['a', 'e', 'i', 'o', 'u', 'y'].includes(letter.toLowerCase());
}

function isPlural(program: string) {
  const firstWord = program.split(' ')[0];
  return firstWord[firstWord.length - 1].toLowerCase() === 's' || firstWord[firstWord.length - 1].toLowerCase() === 'x';
}

export const getTemplateLabels = (grade: string, program: string | undefined) => {
  const g = grade && grade.toLowerCase().trim();
  switch(g) {
    case 'docteur':
      return getDoctoratTemplateLabels();
    default:
      const labels = {...getDefaultTemplateLabels()};
      if (program && isVowel(program[0]) && !isPlural(program)) {
        labels.label_program = labels.label_program + "d'";
      } else if (program && isPlural(program)) {
        labels.label_program = labels.label_program + "des";
      } else {
        labels.label_program = labels.label_program + "de";
      }
      return labels;
  }
}


export const generateSampleInputs = (grade: string) => {
  return [{
    institution: 'UNIVERSITE DE LABE',
    ecole_institut: 'UNIVERSITE SORBONNES',
    nom_prenoms: 'MOHAMED ALIOU SIDIBE',
    ine: '122GMJDHSL989',
    date_naiss_annee: '13 / 11 / 2000',
    date_naiss_mois: '11',
    date_naiss_jour: '13',
    lieu: 'CONAKRY',
    program_etude: 'COMPUTER VISION',
    specialite: 'FACE DETECTION',
    finalite: 'PROFESSIONEL',
    mention: 'TRES BIEN',
    annee_obtention: '2022-2023',
    grade: 'MASTER',
    fait_lieu: 'CONAKRY',
    fait_annee: '12 / 01 / 2022',
    fait_mois: '12',
    fait_jour: '17',
    serial_number: 'N° UL-126653HJGFGSHVJ379NKNS',
    qrcode_id: '11111',
    alinea_1: `Vu la loi L/2023/0016/CNT portant statut particulier des institutions d'enseignement supérieur, de recherche scientifique et des centres de documentation et d'information`,
    alinea_2: `Vu l'arrêté N°A/2019/6855/MESRS/CAB/SGG portant règlementation des études du premier cycle en République de Guinée`,
    alinea_3: `Vu le procés-verbal de délibération du Conseil de l'Université en date de`,
    recteur_valeur_gauche: 'Pr Alpha Kabinet Keita',
    recteur_valeur_droite: 'Pr Moustapha Sangare',
    recteur_label_droite: 'Le Directeur Géneral',
    recteur_label_gauche: 'Le Chef de departement',
    institution_2: 'ÉCOLE DOCTORALE',
    ...getTemplateLabels(grade, 'r'),
  }];
}

const getUnderLine = (n: number) => {let arr = ''; for (let i = 0; i < n; i++) {arr =  arr + 'REPUBLIQUEDEGUINEE'}; return arr};
export const readFile = (
    file: File | null,
    type: "text" | "dataURL" | "arrayBuffer"
  ) => {
    return new Promise<string | ArrayBuffer>((r) => {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (e) => {
        if (e && e.target && e.target.result && file !== null) {
          r(e.target.result);
        }
      });
      if (file !== null) {
        if (type === "text") {
          fileReader.readAsText(file);
        } else if (type === "dataURL") {
          fileReader.readAsDataURL(file);
        } else if (type === "arrayBuffer") {
          fileReader.readAsArrayBuffer(file);
        }
      }
    });
};
  
export const getTemplateFromId = (id: string, templates: ITemplate[]) => {
	return templates.find((tp) => tp.id === id);
}

export const templeDataMapper = (templates: ITemplate[]): ITemplateData[] => {
  return templates?.map((tp) => ({
    id: tp.id!,
    name: tp.name,
    author: tp.author!,
    createdAt: formatDate(tp.createdAt!),
    updatedAt: formatDate(tp.updatedAt!) || formatDate(tp.createdAt!),
  }));
}