import { Box, Button, Modal } from "@mui/material";
import { Template } from "@pdfme/common";
import { Form } from "@pdfme/ui";
import DocEditor from "../DocEditor";
import { LoadingButton } from "@mui/lab";

interface IProps {
    action: () => void;
    template: Template;
    inputs: Record<string, string>[]; 
    open: boolean;
    close: () => void;
    setForm: (form: Form) => void;
    loading?: boolean;
}

const Component: React.FC<IProps> = ({action, template, inputs, open, close, setForm, loading=false}) => {
    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box 
                display="flex"
                flexDirection="column"
                gap={3}
                sx={{background: 'white', p: 2, height: '70%', width: '70%'}}
            >
                <DocEditor template={template} inputs={inputs} setForm={setForm} />
                <Box display="flex" gap={2} width="100%" justifyContent="center">
                    <Button onClick={close} color="error" >Annuler</Button>
                    <LoadingButton loading={loading} onClick={action} color="success">Confirmer</LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}

export default Component;