import { removeAccents } from "../helper";

export const validatedColumns = (mandatoryColumns: string[], columns: string[]): boolean => mandatoryColumns.every((c) => columns.includes(c));

export const normalizeString = (s: string): string => removeAccents(s.trim()).split(' ').join('_').toLowerCase().replace(/[^\w\s]/g, "");

export const normalizeColumnns = (columns: string[]): string[] => columns.map((c) => removeAccents(c.trim()).split(' ').join('_'));

export const getMissingFields= (mandatoryColumns: string[], columns: string[]): string[] => mandatoryColumns.filter((c) => !columns.includes(c));

export const normalizeCsvDataKeys = (data: Record<string, string>): Record<string, string> =>
    Object.entries(data).reduce((acc, curr) => ({...acc, [normalizeString(curr[0])]: curr[1]}), {})


const dateWrongFormat = /^3(?!.*\/)/;

export const sanitizeData = (data: Record<string, string>): Record<string, string> => {
    data['date_naiss'] = (data['date_naiss'] || '').trim();
    if (data['date_naiss'] && data['date_naiss'].split('-').length > 1) {
        data['date_naiss'] = formatDateNaiss(data['date_naiss']);
    }

    if (dateWrongFormat.test(data['date_naiss'])) {
        data['wrong_date_naiss'] = 'true';
    }

    data['nom'] = removeAccents((data['nom'] || "").trim());
    data['prenoms'] = removeAccents((data['prenoms'] || '').trim());
    data['matricule'] = (data['matricule'] || '').replaceAll(/[^a-zA-Z0-9 ]/g, '');
    data['institution'] = (data['institution'] || '').trim();
    data['ine'] = (data['ine'] || '').replaceAll(/[^a-zA-Z0-9 ]/g, '');

    return Object.entries(data).filter(([key, _]) => key && key.trim() !== '' && key.trim().length > 2).reduce((acc, curr) => ({...acc, [curr[0]]: curr[1]}), {});
}

const formatDateNaiss = (date: string): string => {
    const temp = date.split('-').map(d => d.trim());
    while (temp.length < 3) {
        temp.push('00');
    }
    
    return temp[2]+'/'+temp[1]+'/'+temp[0];
}