import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from "@mui/lab";
import { IconButton, Modal } from '@mui/material';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Template } from '@pdfme/common';
import { barcodes, text } from '@pdfme/schemas';
import { Designer } from "@pdfme/ui";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DocViewer from '../components/DocViewer';
import { ITemplate } from "../entities/organization";
import { defaultTemplate, generateSampleInputs, getTemplateFromId, readFile } from "../lib/Template";
import { useTemplateFonts } from '../lib/contexts/TemplateFonts';
import { AppDispatch, IState } from "../lib/store";
import { createNewTemplate, updateTemplate } from "../lib/store/organization/thunks";

interface ILoading {
    new: boolean;
    update: boolean;
}

export default () => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<ILoading>({new: false, update: false});

    const designerRef = useRef<HTMLDivElement | null>(null);
    const designer = useRef<Designer | null>(null);

    const {tpID} = useParams();
    const navigate = useNavigate();

    const templates = useSelector((state: IState) => state.organization.orgs[0].templates, shallowEqual)!;
    const [template, setTemplate] = useState<Template>(defaultTemplate);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [previewMode, setPreviewMode] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [author, setAuthor] = useState<string>();
    const templateFonts = useTemplateFonts();

    useEffect(() => {
        if (designerRef.current) {
            console.log(templateFonts.fonts)
            let tp = {template} as ITemplate;
            if (tpID && tpID !== "new" ) {
                tp = getTemplateFromId(tpID, templates)!;
                if (!tp) {
                    navigate('/templates');
                    return;
                }
                setTemplate(tp.template);
                setName(tp.name);
                setAuthor(tp.author)
                setIsNew(false);
            }

            designer.current = new Designer({
                domContainer: designerRef.current!,
                template: tp.template,
                plugins: {
                    text,
                    qrcode: barcodes.qrcode,
                },
                options: {
                    font: templateFonts.fonts
                }
            });
            designer.current.onChangeTemplate(setTemplate);
        }
        return () => {
        //   designer.current?.destroy();
        };
    }, [designerRef]);

    const _createNewTemplate = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading({...loading, new: true});
        dispatch(createNewTemplate({name, template})).unwrap()
            .then(() => {
                toast.success('Nouveau template créé avec succès');
                navigate(-1);
            })
            .catch(() => toast.error('une erreur est survenue, veuillez re-essayer. Si le problème persiste contactez nous !'))
            .finally(() => setLoading({...loading, new: false}));
    }

    const _updateTemplate = () => {
        setLoading({...loading, update: true});
        dispatch(updateTemplate({id: tpID, name, author, template})).unwrap()
            .then(() => toast.success('Template modifié avec succès'))
            .catch(() => toast.error('une erreur est survenue, veuillez re-essayer. Si le problème persiste contactez nous !'))
            .finally(() => setLoading({...loading, update: false}));
    }

    const onChangeBasePDF = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files) {
          readFile(e.target.files[0], "dataURL").then(async (basePdf) => {
            if (designer.current) {
              designer.current.updateTemplate(
                Object.assign(designer.current.getTemplate(), {
                  basePdf,
                })
              );
            }
          });
        }
    };

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                mb={1}
                component={"form"}
                onSubmit={_createNewTemplate}
                overflow={'scroll'}
            >

                <TextField 
                    InputLabelProps={{ required: false }}
                    id="outlined-basic"
                    value={name}
                    label="Nom"
                    variant="outlined"
                    onChange={(event: any) => setName(event.target.value!)}
                    size="small" required
                />
                
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    gap={2}
                >

                    <Button variant="outlined" size="small" sx={{fontSize: '10px'}} color="warning">
                        <UploadFileIcon />
                        <label htmlFor="base">
                            Changer la base
                            <input id="base" type="file" accept="application/pdf" style={{display: 'none', width: '100%'}} onChange={onChangeBasePDF} />
                        </label>
                    </Button>
                    {
                        isNew ?
                            (
                                <LoadingButton loading={loading.new} variant="outlined" size="small" sx={{fontSize: '10px'}} color="success" type="submit">
                                    <AddIcon />
                                    Créer
                                </LoadingButton>

                            ):(
                                <LoadingButton loading={loading.update} variant="outlined" size="small" sx={{fontSize: '10px'}} color="info" onClick={_updateTemplate}>
                                    <SaveIcon />
                                    Enregister les modifications
                                </LoadingButton>  
                            )
                    }
                    <Button size="small" sx={{fontSize: '10px'}} color="secondary" variant="outlined"  onClick={() => setPreviewMode(true)}>
                        <PreviewIcon /> Prévisualiser
                    </Button>
                </Box>

            </Box>
            <div
                ref={designerRef}
                style={{ width: '100%', height: '100%'}}
            />

            <View grade={name?.split(' ')[0].toLowerCase()} open={previewMode} template={template} close={() => setPreviewMode(false)}/>
        </>
    )

}

const View: React.FC<{open: boolean; grade: string; template: Template, close: () => void}> = ({open, grade, close, template}) => {
    const inputs = generateSampleInputs(grade);
    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box 
                display="flex"
                flexDirection="column"
                gap={3}
                sx={{background: 'white', p: 2, height: '70%', width: '70%'}}
            >
                <DocViewer template={template} inputs={inputs}/>
                <Box display="flex" gap={2} width="100%" justifyContent="center">
                    <IconButton onClick={close} color="error" >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}