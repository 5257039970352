import { FILE_PROCESSOR_API } from "../../config/environment.dev";
import { ITemplate } from "../../entities/organization";
import { formatDate, getDiplomaQrcodeValue, getDiplomaSerialNumber, getSigle, removeAccents } from "../../lib/helper";
import { client } from "../../lib/http-client";
import { getTemplateLabels } from "../../lib/Template";

export const capitallizeFirstLetter = (s: string) => {
    const res = s.toLowerCase();
    return res.charAt(0).toUpperCase() + res.slice(1);
}

export const ALINEA_LICENCE = {
    alinea_1: `Vu la loi L/2023/0016/CNT portant statut particulier des institutions d'enseignement supérieur, de recherche scientifique et des centres de documentation et d'information;`,
    alinea_2: `Vu l'arrêté N°A/2019/6979/MESRS/SGG portant règlementation des études de licence en République de Guinée;`,
    alinea_3: `Vu le procès-verbal de délibération du Conseil de`
}

export const ALINEA_MASTER = {
    alinea_1: `Vu la loi L/2023/0016/CNT portant statut particulier des institutions d'enseignement supérieur, de recherche scientifique et des centres de documentation et d'information;`,
    alinea_2: `Vu l'arrêté N°A/2019/6854/MESRS/SGG portant règlementation des études de master en République de Guinée;`,
    alinea_3: `Vu le procès-verbal de délibération du jury de soutenance en date du`,
}

export const ALINEA_DOCTEUR = {
    alinea_1: `Vu la loi L/2023/0016/CNT portant statut particulier des institutions d'enseignement supérieur, de recherche scientifique et des centres de documentation et d'information;`,
    alinea_2: `Vu l'arrêté N°A/2019/6856/MESRS/SGG portant règlementation des études de doctorat en République de Guinée;`,
    alinea_3: `Vu le procès-verbal de délibération du jury de soutenance en date du`,
}

const DEFAULT_ALINEA = {
    alinea_1: ' ',
    alinea_2: ' ',
    alinea_3: ' '
}

export const getGrade = (grade: string, program: string) => {
    if (grade.toLowerCase().includes('licence')) {
        return 'LICENCE';
    }

    if (grade.toLowerCase().includes('master')) {
        return 'MASTER';
    }

    if (grade.toLowerCase().includes('docteur')) {
        switch(removeAccents(program).trim().toUpperCase()){
            case 'MEDECINE VETERINAIRE':
                return "DOCTEUR D'ETAT";
            default:
                return 'DOCTEUR';
        }
    }

    if (removeAccents(grade.toLowerCase()).includes('ingenieur')) {
        return 'MASTER';
    }

    return 'INDISPONIBLE';
}

export const getAlineas = (grade: string) => {
    if (grade.toLowerCase().includes('licence')) {
        return ALINEA_LICENCE;
    }

    if (grade.toLowerCase().includes('master')) {
        return ALINEA_MASTER;
    }

    if (grade.toLowerCase().includes('docteur')) {
        return ALINEA_DOCTEUR;
    }

    if (removeAccents(grade).toLowerCase().includes('ingenieur')) {
        return ALINEA_MASTER;
    }

    return DEFAULT_ALINEA;
}

export const getTemplate = (type: string, programEtude: string | undefined = undefined) => (templates: ITemplate[], version: string): {preview: ITemplate, print: ITemplate} =>  {
    const ret: {preview: ITemplate, print: ITemplate} = {} as any;
    templates?.map((tpl) => {
        if (programEtude && programEtude.toLowerCase().split(' ').some((p) => tpl.name.toLowerCase().split(' ').includes(p))) {
            ret.print = tpl;
            return ret;
        }

        if (([type.trim().toLocaleLowerCase(), `${type.trim().toLocaleLowerCase()} v2`, `_${type}`.trim().toLocaleLowerCase()].includes(tpl.name.toLocaleLowerCase()))) {
            if (version == 'v1' && tpl.name.includes('_')) {
                ret.print = tpl;
            } else if (version == 'v2' && tpl.name.includes('v2')) {
                ret.print = tpl;
            }
        }
        ret.preview = tpl;
    });
    return ret;
}


export const enhanceTemplate = (template: ITemplate, isEcoleInstitut: boolean, institution: string, specialite: string) => {
    if (!template.name.includes('v2')) {
        return template;
    }

    const getInstitutFontSize = (baseSize: number) => {
        if (institution.length >= 55) {
            return 18;
        }

        if (institution.length >= 40) {
            return 22;
        }
        return baseSize + 9;
    }

    const tpl = JSON.parse(JSON.stringify(template.template));

    if (institution.toLowerCase().includes('dalaba') && (template.name.toLowerCase().includes('medecine') || template.name.toLowerCase().includes('ingenieur'))) {
        delete tpl.schemas[0]['alinea_2'];
        ['alinea_3'].forEach((field) => {
            if (!tpl.schemas[0][field]) {
                return;
            }
            tpl.schemas[0][field].position.y -= 5;
        })
    }

    if (!specialite || specialite == ' ') {
        delete tpl.schemas[0]['specialite'];
        delete tpl.schemas[0]['underline_speciality'];
        delete tpl.schemas[0]['label_speciality'];
        delete tpl.schemas[0]['label_doctorat_program'];
        [
            'label_finalite',
            'finalite',
            'underline_finalite',
            'label_mention',
            'mention',
            'underline_mention',
            'label_session',
            'annee_obtention',
            'underline_session',
            'label_grade',
            'grade',
            'underline_grade',
            'label_end_text',
            'label_edited_at',
            'fait_lieu',
            'underline_edited_at',
            'label_edited_on',
            'fait_annee',
            'underline_edited_on',
            'label_intitule_diplome',
            'underline_intitule_diplome_1',
            'intitule_diplome_1',
            'underline_intitule_diplome_2',
            'intitule_diplome_2',
            'underline_doctorat_session',
            'underline_doctorat_grade',
            'underline_doctorat_mention',
            
        ].forEach((field) => {
            if (!tpl.schemas[0][field]) {
                return;
            }
            tpl.schemas[0][field].position.y -= 9;
        })
    }

    if (!isEcoleInstitut) {
        tpl.schemas[0]['institution'] = {
            ...tpl.schemas[0]['institution'],
            position: {
                ...tpl.schemas[0]['institution'].position,
                y: tpl.schemas[0]['institution'].position.y + 2,
            }
        };
    } else {
        tpl.schemas[0]['ecole_institut'] = {
            ...tpl.schemas[0]['ecole_institut'],
            position: {
                ...tpl.schemas[0]['ecole_institut'].position,
                y: tpl.schemas[0]['ecole_institut'].position.y - 1,
            },
            fontSize: tpl.schemas[0]['ecole_institut'].fontSize + 2
        };
    }

    tpl.schemas[0]['institution'] = {
        ...tpl.schemas[0]['institution'],
        fontSize:  getInstitutFontSize(tpl.schemas[0]['institution'].fontSize),
    };

    template.template = tpl;
    return template;
} 

export const generatePDF = async (templateId: string, inputs: Record<string, Record<string, string>[]>): Promise<Record<string, any>> => {
    const input = Object.values(inputs)[0];

    const response = await client.post<Record<string, any>>(`${FILE_PROCESSOR_API}/generate-diploma`, {
        templateId,
        datas: inputs,
        commonData: getTemplateLabels(input[0]['grade']?.toLowerCase(), input[0]['program_etude'])
    });
    return response.data;
}

export const sanitizeUniversityName = (name: string): string => {
    const s = name && name.split('-').map((e) => e.trim()).reverse();
    if (!s) {
        return name;
    }
    return s[0];
}

interface IUniversityMetadata {
    values: {
        [sigle: string]: {
            isUniv?: boolean,
            lieu: string,
            signataires?: {
                defaultLabels: {
                    left: string,
                    right: string
                },
                [program: string]: {
                    left?: string,
                    right?: string
                },
            }
        }
    }
}

export const getSignatairesLabels = (univName: string, program: string, grade='licence', values: Record<string, string> | undefined = undefined): {left: string; right: string} => {
    if (values && values['signataire_1_label'] && values['signataire_2_label']) {
        return {
            left: values['signataire_1_label'],
            right: values['signataire_2_label']
        }
    }

    if (['docteur'].includes(grade.toLowerCase())) {
        return {
            left: 'Le Chef Service des Études Avancées',
            right: 'Le Recteur'
        }
    }

    const sigle = getSigle(removeAccents(univName.trim()));
    const metadata = uniVMetadatas.values[sigle];
    const p = removeAccents(program).split(" ").join("_");

    if (sigle.toLowerCase() === 'ujnk' && grade.toLowerCase().trim() == 'master') {
        return {
            left: 'Le Chef Service des Études Avancées',
            right: 'Le Recteur'
        }
    }

    if (metadata.signataires) {
        const mp = metadata.signataires[p];
        if (mp && mp.left && mp.right) {
            return {
                left: mp.left,
                right: mp.right
            }
        }
        return {
            left: metadata.signataires.defaultLabels.left,
            right:  metadata.signataires.defaultLabels.right
        }
    }

    if (['docteur', 'master'].includes(grade.toLowerCase())) {
        let left = 'Le Directeur de l\'Ecole Doctorale';
        if (sigle === 'UGANC') {
            left = 'Le Chef Service des Études Avancées'
        }
        return {
            left: left,
            right: 'Le Recteur'
        }
    }


    if (!metadata || (metadata.isUniv && !metadata.signataires)) {
        return {
            left: 'Le Doyen',
            right: 'Le Recteur'
        }
    }

    return {
        left: 'Le Chef de Département',
        right: 'Le Directeur Général'
    }
}

export const uniVMetadatas: IUniversityMetadata = {
    values: {
        UGANC: {
            isUniv: true,
            lieu: 'Conakry',
            signataires: {
                defaultLabels: {
                    left: 'Le Doyen',
                    right: 'Le Recteur'
                },
                Informatique: {
                    left: 'Le Directeur Géneral',
                    right: 'Le Recteur'
                },
                Genie_Civil: {
                    left: 'Le Directeur Géneral',
                    right: 'Le Recteur'
                },
                Genie_Electronique: {
                    left: 'Le Directeur Géneral',
                    right: 'Le Recteur' 
                },
                Genie_Informatique: {
                    left: 'Le Directeur Géneral',
                    right: 'Le Recteur' 
                },
                Agriculture_Durable_et_Gestion_des_Ressources_en_Eau: {
                    left:  'Le Chef Service des Études Avancées',
                    right: 'Le Recteur' 
                },
                Systemes_Energetiques_et_Environnement: {
                    left:  'Le Chef Service des Études Avancées',
                    right: 'Le Recteur'
                }
            },
        },
        UJNK: {
            isUniv: true,
            lieu: 'Kankan',
            signataires: {
                defaultLabels: {
                    left: 'Le Doyen',
                    right: 'Le Recteur'
                },
                Archives: {
                    left: 'Le Directeur',
                    right: 'Le Recteur'
                },
                Documentation: {
                    left: 'Le Directeur',
                    right: 'Le Recteur'
                }
            }
        },
        UGLCSC: {
            isUniv: true,
            lieu: 'Conakry'
        },
        UL: {
            isUniv: true,
            lieu: 'Labe'
        },
        UK: {
            isUniv: true,
            lieu: 'Kindia'
        },
        UZ: {
            isUniv: true,
            lieu: 'N’zerekore'
        },
        ISSEG: {
            lieu: 'Conakry'
        },
        ISAV: {
            lieu: 'Faranah',
            signataires: {
                defaultLabels: {
                    right: 'La Directrice Générale',
                    left: 'Le Chef de Département'
                },
                AGROFORESTERIE: {
                    right: 'La Directrice Générale',
                    left: 'La Cheffe de Département'
                },
                'AGRICULTURE_DURABLE_ET_GESTION_DES_RESSOURCES_EN_EAU': {
                    right: 'La Directrice Générale',
                    left: 'Le Chef Service des Études Avancées'
                }
            },
        },
        ISAMK: {
            lieu: 'Dubreka',
        },
        ISMGB: {
            lieu: 'Boke',
            signataires: {
                defaultLabels: {
                    right: 'Le Directeur Général',
                    left: 'Le Chef de Département'
                },
                MAGEM: {
                    right: 'Le Directeur Général',
                    left: 'Le Chef Service des Etudes Avancées'
                }
            },
        },
        ISAG: {
            lieu: 'Dubreka'
        },
        ISFAD: {
            lieu: 'Conakry'
        },
        ISTM: {
            lieu: 'Mamou'
        },
        ISSMV: {
            lieu: 'Dalaba',
            signataires: {
                defaultLabels: {
                    right: 'Le Directeur Général',
                    left: 'Le Chef de Département'
                },
            }
        },
        ISIC: {
            lieu: 'Conakry',
            signataires: {
                defaultLabels: {
                    right: 'La Directrice Générale',
                    left: 'Le Chef de Département'
                },
                Communication: {
                    right: 'La Directrice Générale',
                    left: 'Le Chef de Département par Intérim'
                },
                COMMUNICATION: {
                    right: 'La Directrice Générale',
                    left: 'Le Chef de Département par Intérim'
                }
            }
        },
        ISCAEG: {
            lieu: 'Conakry'
        },
        ISAU: {
            lieu: 'Conakry',
            signataires: {
                defaultLabels: {
                    left: 'Le Chef de Département',
                    right: 'Le Directeur Général'
                }
            },
        },
        ESTH: {
            lieu: 'Conakry'
        },
    }
}

export const enhanceMetadata = (metadata: Record<string, string>, universityName: string): Record<string, string> => {
    const metad: Record<string, string> = {...metadata!};
    metad['institution'] = sanitizeUniversityName(removeAccents(universityName));
    metad['ecole_institut'] =   metad['ecole_institut'] || ' ';
    metad['specialite'] =  metad['specialite'] || ' ';
    metad['fait_lieu'] =  'CONAKRY';
    metad['serial_number'] =  getDiplomaSerialNumber(universityName);;
    metad['nom_prenoms'] = [metad['prenoms'], metad['nom']].map((s) => s.trim()).join(' ');

    const date = formatDate(Date.now()).split('/');
    metad['fait_jour'] =  date[0];
    metad['fait_mois'] =  date[1];
    metad['fait_annee'] =  [date[0], date[1], date[2]].join(' / ');

    Object.entries(metad).map(([k, v]) => {
        metad[k] = v && removeAccents(v.toUpperCase());
    });

    const sigle = getSigle(universityName);
    const university = uniVMetadatas.values[sigle];

    const signatairesLabels = getSignatairesLabels(universityName, metadata['program_etude'], metadata['grade'], metadata);

    metad['finalite'] = metadata!['finalite']?.toUpperCase()  || 'PROFESSIONNELLE';
    if (['UJNK'].includes(metadata!['institution'])) {
        metad['finalite'] = 'FONDAMENTALE';
    }
    metad['recteur_valeur_gauche'] = (metadata!['first_signataire'] || "").trim();
    metad['recteur_valeur_droite'] = (metadata!['second_signataire'] || "").trim();
    metad['recteur_label_gauche'] = signatairesLabels.left;
    metad['recteur_label_droite'] = signatairesLabels.right;

    const alineas = getAlineas(metadata!['grade']);
    Object.entries(alineas).map(([k, v]) => {
        metad[k] = v;
    });

    const alinea3 = metad['alinea_3'];
    metad['alinea_3'] = `${alinea3} l'Institut en date du ${metadata!['conseil_date']}.`;
    if (university) {
        metad['fait_lieu'] =  university.lieu.toUpperCase();
        if (university.isUniv) {
            metad['finalite'] = metadata!['finalite']?.toUpperCase()  || "FONDAMENTALE";
            metad['alinea_3'] = `${alinea3} l'Université en date du ${metadata!['conseil_date']}.`;
        }
    }

    if (alinea3 === ' ') {
        metad['alinea_3'] = ' ';
    }

    if (['docteur', 'master', 'ingenieur'].includes(metadata!['grade'].toLowerCase().trim())) {
        metad['alinea_3'] = `${alinea3} ${metadata!['conseil_date']}.`;
    }

    const birthDate = metad['date_naiss'].split('/');
    if (birthDate.length > 0) {
        metad['date_naiss_jour'] = birthDate[0];
        metad['date_naiss_mois'] = birthDate[1];
        metad['date_naiss_annee'] = [ birthDate[0],  birthDate[1], birthDate[2]].join(' / ');
    }
    metad['grade'] = getGrade(metad['grade']!, metadata['program_etude']);
    metad['qrcode_id'] = getDiplomaQrcodeValue(metadata['hash']);
    metad['hash'] = metadata['hash'];


    if (["DOCTEUR D'ETAT"].includes(metad['grade']) || ['ingenieur'].includes(metadata!['grade'].toLowerCase().trim())) {
        metad['alinea_3'] = `${ALINEA_LICENCE.alinea_3} l'Institut en date du ${metadata!['conseil_date']}.`;
    }

    if (metad['grade'].includes("DOCTEUR")) {
        const intituleDiplome =  (metadata['intitule_diplome'] || "").trim()
        metad['intitule_diplome_1'] = intituleDiplome;
        metad['intitule_diplome_2'] = ' ';

        if (intituleDiplome.length > 116) {
            const substr = intituleDiplome.slice(116, intituleDiplome.length);
            const limit = 116 + findNextCharAfterDelimiter(substr, ' ');
            metad['intitule_diplome_1'] = intituleDiplome.slice(0, limit);
            metad['intitule_diplome_2'] = intituleDiplome.slice(limit, intituleDiplome.length);
        }
    }
    return {
        ...metad,
        ...getTemplateLabels(metadata['grade'].toLowerCase().trim(), metad['program_etude'])
    };
}

const findNextCharAfterDelimiter = (s: string, delimiter: string): number => {
    for (let i = 0; i < s.length; i++) {
        if (s[i] == delimiter) {
            return i+1;
        }
    }
    return 0;
}